import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./CategoryGrpList.module.css";

const CategoryGrpList = ({ list, height }) => {
  return (
    <Box className={styles?.["main-view"]} sx={{ height: height }}>
      {list.map((item, index) => {
        return (
          <Box key={index} className={styles?.["main-item-view"]}>
            <Box key={index} className={styles?.["item-view"]}>
              <Box className={styles?.["img-view"]}>
                <img
                  alt="img"
                  src={item?.image}
                  className={styles?.["item-img"]}
                />
              </Box>
              <Box className={styles?.["bg-shade"]} />
              <Typography className={styles?.["item-text"]}>
                {item.name}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CategoryGrpList;

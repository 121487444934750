import React, { useState, useEffect } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Box, Typography, Button, CircularProgress, TextField } from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./CardPaymentForm.scss";

const CardPaymentForm = ({
    amount,
    onSuccess,
    onCustomerInfoChange,
    paymentIntentId,
    clientSecret,
    setFinalLoading,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isRetry, setIsRetry] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isCardComplete, setIsCardComplete] = useState(false);
    const [cardError, setCardError] = useState(null);

    useEffect(() => {
        if (!stripe || !elements) {
            setFinalLoading(true);
        }
    }, [stripe, elements, setFinalLoading]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements || !paymentIntentId) {
            console.error("Stripe.js has not loaded or paymentIntentId is missing.");
            return;
        }

        setIsProcessing(true);

        try {
          await onCustomerInfoChange(name, email);

          const { error, paymentIntent } =  await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: `${window.location.origin}/order-success/${paymentIntentId}`,
            },
            redirect: "if_required"
          });

          if (error) {
                setErrorMessage(error.message);
                setIsProcessing(false);
                return;
            }

            if (paymentIntent.status === "succeeded") {
                onSuccess(paymentIntent.id);
            }
        } catch (error) {
            console.error("Error during payment process:", error);
            setErrorMessage(error.message);
            setIsProcessing(false);
        }
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        onCustomerInfoChange(newName, email);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        onCustomerInfoChange(name, newEmail);
    };

    const handleCardChange = (event) => {
        setIsCardComplete(event.complete);
        setCardError(event.error ? event.error.message : null);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography
					variant='body2'
					sx={{
						textAlign: "center",
						my: 2,
						fontSize: 14,
					}}
				>
					or Pay with Card
				</Typography>
            <TransitionGroup>
                <CSSTransition key="name" timeout={500} classNames="fade-slide">
                        <TextField
                            label='Name'
                            value={name}
                            onChange={handleNameChange}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                        />
                </CSSTransition>
                <CSSTransition key="email" timeout={500} classNames="fade-slide">
                        <TextField
                            label='Email'
                            type='email'
                            value={email}
                            onChange={handleEmailChange}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                        />
                </CSSTransition>
                <CSSTransition key="card" timeout={500} classNames="fade-slide">
                    <Box
                        sx={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            padding: "16px",
                        }}
                    >
                        <PaymentElement onChange={handleCardChange} />
                    </Box>
                </CSSTransition>
                <CSSTransition key="button" timeout={500} classNames="fade-slide">
                    <Box my={2}>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth
                            disabled={
                                !stripe ||
                                isProcessing ||
                                !isCardComplete
                            }
                            sx={{
                                height: 48,
                                backgroundColor: isProcessing ? "grey.500" : "#1d3153",
                            }}
                        >
                            {isProcessing ? (
                                <CircularProgress size={24} />
                            ) : isRetry ? (
                                "Retry Payment"
                            ) : (
                                "Pay by Card"
                            )}
                        </Button>
                    </Box>
                </CSSTransition>
            </TransitionGroup>
            {cardError && (
                <Typography color='error' mt={2}>
                    {cardError}
                </Typography>
            )}
            {errorMessage && (
                <Typography color='error' mt={2}>
                    {errorMessage}
                </Typography>
            )}
        </form>
    );
};

export default CardPaymentForm;
